import {
    getRefEntityName,
    isADirectRefOneField,
    isADirectValuesetOneField,
} from '../../../../../../components/generics/utils/viewConfigUtils';
import set from 'lodash/set';
import ViewConfigEntities from '@mkanai/casetivity-shared-js/lib/view-config/entities';
import { flatten } from 'flat';
// More heavy duty trie, where each node holds both a value, and child nodes
import { Trie } from './Trie';
import { buildTrie } from '../createPathTreeFromGraph';
import expandMinimalDataFromDataPaths from '../expandMinimalDataFromPaths';

const updateDataOnRefChange = <ViewConfig extends { entities: ViewConfigEntities }>(
    viewConfig: ViewConfig,
    baseEntityType: string,
    entities: {},
    fieldValuePairs: [string, unknown][],
    expressionAndReadOnlyFields: string[], // these are what we need to lookup based on reference changes
) => {
    const expTrie = buildTrie(expressionAndReadOnlyFields);

    const fieldValueTrie = new Trie();

    const expansionPaths = [];
    fieldValuePairs.forEach(([f, value]) => {
        fieldValueTrie.insert(f, value);
        if (f.endsWith('Ids')) {
            return;
        }
        try {
            const expansionPath = f.endsWith('Id') ? f.slice(0, -2) : f;
            const isDirectRef1 = isADirectRefOneField(viewConfig, baseEntityType, expansionPath);
            const isDirectVs1 = isADirectValuesetOneField(viewConfig, baseEntityType, expansionPath);
            if (isDirectRef1 || isDirectVs1) {
                const refEntity = isDirectVs1
                    ? 'Concept'
                    : getRefEntityName(viewConfig, baseEntityType, expansionPath, 'TRAVERSE_PATH');
                if (refEntity) {
                    expansionPaths.push({
                        expansionPath,
                        refEntity,
                        value: value as string,
                    });
                }
            }
        } catch (e) {
            console.error(e);
            console.log('Error above occurred building expansionsPaths on ' + baseEntityType);
        }
    });

    const dataAtPaths = expansionPaths
        .map(({ expansionPath, refEntity, value }) => {
            const afterBase = expansionPath.split('.').reduce((prev, curr) => {
                if (prev) {
                    return prev[curr];
                }
                return prev;
            }, expTrie);

            const pathsAfterBase = afterBase ? Object.keys(flatten(afterBase)) : [];
            const pathsToExpandOnRef = pathsAfterBase.filter((p) => {
                try {
                    return (
                        !isADirectRefOneField(viewConfig, refEntity, p) &&
                        !isADirectValuesetOneField(viewConfig, refEntity, p)
                    );
                } catch (e) {
                    return true;
                }
            });
            const v = (() => {
                const res =
                    expandMinimalDataFromDataPaths(viewConfig, refEntity, value, entities, pathsToExpandOnRef) ?? {};
                fieldValueTrie.traverse(expansionPath, (node, path) => {
                    if (path === expansionPath) {
                        // ignore the root node.
                        return;
                    }
                    set(res, path.slice(expansionPath.length + 1), node.value);
                });
                return res;
            })();
            const keys = Object.keys(v);
            if (refEntity === 'Concept' && keys.length === 0) {
                // we never are actually overwriting this data, so never null it out (like we do in the lines below).
                // only update fields like '.group' or '.code' to whatever is needed on the backend.
                return null;
            }
            return [expansionPath, keys.length > 0 ? v : null] as [string, null | {}];
        })
        .filter(Boolean);
    return dataAtPaths;
};

export default updateDataOnRefChange;
