import * as colors from '@material-ui/core/colors';
import grey from '@material-ui/core/colors/grey';
import updateRwtStylesheet from './rwtStyleInjector';
import { TextFieldProps, Theme, ThemeOptions as V4ThemeOptions, alpha } from '@material-ui/core';
type color = keyof typeof colors;

const getInitialTheme = (
    themeCreator: (themeInit: V4ThemeOptions) => Theme,
    args: {
        primary: color;
        secondary: color;
        error: color;
        darknessOverrides?: {
            primary?: any;
            secondary?: any;
            error?: any;
        };
        darkMode?: boolean;
        forceLabelShrink?: boolean;
        fieldVariant?: TextFieldProps['variant'];
        fontFamily?: string;
        printMode?: boolean;
        useStripedLists?: boolean;
        denseUI?: boolean;
        flatUI?: boolean;
    },
    rebuildRWTStylesheet: boolean = false,
) => {
    const {
        primary,
        secondary,
        error,
        darknessOverrides = {},
        darkMode = false,
        forceLabelShrink,
        fieldVariant,
        fontFamily,
        useStripedLists,
        denseUI = false,
        flatUI = false,
    } = args;

    const errorColor = colors[error];

    type ColorKey = keyof typeof colors.amber;
    const getRange = (attr?: keyof typeof darknessOverrides): [ColorKey, ColorKey, ColorKey] => {
        const normalizeColor = (override: number) => {
            return Math.min(Math.max(50, override), 100);
        };

        const override = darknessOverrides[attr];
        if (override) {
            if (typeof override === 'string' && override.startsWith('A')) {
                const aKeys: (keyof typeof colors.amber)[] = ['A100', 'A200', 'A400', 'A700'];
                return [aKeys.find((k) => k <= override), override as ColorKey, aKeys.find((k) => k >= override)];
            }
            return [normalizeColor(override - 100) as ColorKey, override, normalizeColor(override + 100) as ColorKey];
        }
        switch (attr) {
            case 'error':
                return [600, 900, 900];
            case 'primary':
                return [800, 900, 900];
            case 'secondary':
                return [300, 500, 700];
        }
    };
    const primaryIx = getRange('primary');
    const secondaryIx = getRange('secondary');
    const errorIx = getRange('error');
    const v1Palette = {
        type: darkMode ? ('dark' as const) : undefined,
        primary: (() => {
            if (primary.startsWith('#')) {
                return {
                    main: primary,
                };
            }
            const primaryColor = colors[primary];
            return {
                light: primaryColor[primaryIx[0]],
                main: primaryColor[primaryIx[1]],
                dark: primaryColor[primaryIx[2]],
            };
        })(),
        secondary: (() => {
            if (secondary.startsWith('#')) {
                return {
                    main: secondary,
                };
            }
            const secondaryColor = colors[secondary];
            return {
                light: secondaryColor[secondaryIx[0]],
                main: secondaryColor[secondaryIx[1]],
                dark: secondaryColor[secondaryIx[2]],
            };
        })(),
        error: {
            light: errorColor[errorIx[0]],
            main: errorColor[errorIx[1]],
            dark: errorColor[errorIx[2]],
        },
        warning: {
            main: '#bd5800',
        },
    };
    const greyInputBackground =
        !args.printMode && !darkMode && (!fieldVariant || fieldVariant === 'standard') && forceLabelShrink !== false;
    const themeV1Init: V4ThemeOptions = {
        spacing: (factor) => `${factor * 6}px`, // Default spacing is * 8
        palette: v1Palette,
        typography: args.printMode
            ? {
                  fontFamily,
                  fontSize: 11,
              }
            : {
                  fontFamily,
                  fontSize: denseUI ? 12 : undefined, // TODO possibly control this separately
              },
        props: {
            MuiAccordion: {
                variant: flatUI ? 'outlined' : undefined,
            },
            MuiCard: {
                variant: flatUI ? 'outlined' : undefined,
            },
            MuiChip: {
                size: denseUI ? 'small' : undefined,
            },
            MuiFormControl: {
                margin: denseUI ? 'none' : undefined,
                variant: fieldVariant,
            },
            MuiOutlinedInput: {
                margin: denseUI ? 'dense' : undefined,
                notched: forceLabelShrink ? true : undefined,
            },
            MuiFilledInput: {
                margin: denseUI ? 'dense' : undefined,
            },
            MuiTextField: {
                size: denseUI ? 'small' : undefined,
                variant: fieldVariant,
                InputLabelProps: {
                    shrink: forceLabelShrink,
                },
            },
            MuiButton: {
                disableElevation: flatUI ? true : undefined,
                size: denseUI ? 'small' : undefined,
            },
            MuiIconButton: {
                size: denseUI ? 'small' : undefined,
            },
            MuiFab: {
                size: denseUI ? 'small' : undefined,
            },
            MuiCheckbox: {
                size: denseUI ? 'small' : undefined,
            },
            MuiRadio: {
                size: denseUI ? 'small' : undefined,
            },
            MuiSwitch: {
                size: denseUI ? 'small' : undefined,
            },
        },
        overrides: {
            MuiTableRow: useStripedLists && {
                root: {
                    '&:not($head)': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: alpha(v1Palette.primary.main, 0.03),
                            // backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                            },
                        },
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.05) !important',
                        },
                    },
                },
            },
            /* Input style class overrides below */
            MuiButton: {
                outlined: {
                    '&$disabled': {
                        color: colors.grey[600],
                    },
                },
                label: {
                    textTransform: 'capitalize' as 'capitalize',
                },
            },
            MuiCard: {
                root: {
                    overflow: 'unset', // prevents dropdowns from getting cut off
                },
            },
            MuiFormLabel:
                !darkMode && (!fieldVariant || fieldVariant === 'standard') && forceLabelShrink !== false
                    ? {
                          /*
                    Explains why "focused: { color: 'red' }" doesn't work as an override here.
                    https://github.com/mui-org/material-ui/issues/11244#issuecomment-386792253
                */
                          root: {
                              color: grey[900], // dark label by default
                              '&$disabled': {
                                  color: grey[900],
                              },
                              '&$focused': {
                                  color: v1Palette.primary.main, // primary color when focused.
                              },
                              '&$error': {
                                  color: grey[900], // dark label by default
                              },
                          },
                      }
                    : fieldVariant === 'outlined'
                    ? {
                          root: {
                              '&$disabled': {
                                  color: '#595959',
                              },
                          },
                      }
                    : undefined,
            MuiMenuItem: greyInputBackground
                ? {
                      root: {
                          '&:focus': {
                              backgroundColor: '#DEDEDE',
                          },
                      },
                  }
                : undefined,
            MuiInput: greyInputBackground
                ? {
                      root: {
                          backgroundColor: grey[100],
                          '&$disabled': {
                              backgroundColor: 'transparent',
                          },
                      },
                      underline: {
                          borderBottomColor: 'red',
                          '&:hover:not($disabled):after': {
                              borderBottomColor: v1Palette.primary.main, // selected AND hovered
                          },
                          /* '&:hover:not($disabled):before': {
                        borderBottomColor: 'green',
                    }, */
                          /* '&:before': {
                        borderBottomColor: grey[900], // resting underline color (not focused or hovered)
                    }, */
                          '&:hover:not($disabled):not($error):not($focused):before': {
                              borderBottomColor: v1Palette.primary.main, // hovered but not focused (and no error)
                          },
                      },
                  }
                : undefined,
            MuiInputBase: greyInputBackground
                ? {
                      root: {
                          '&$disabled': {
                              color: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)',
                          },
                      },
                  }
                : undefined,
            /*
            MuiTableRow: {
                root: {
                    '&$hover': {
                        backgroundColor: '#E0E0E0',
                    },
                },
            },
            */
            MuiFormHelperText: {
                root: {
                    '&$error': {
                        whiteSpace: 'pre-wrap',
                    },
                },
            },
            MuiFormControl: {
                root: {
                    zIndex: 'unset' as const,
                },
            },
            MuiFilledInput: {
                root: {
                    // Match Filled Input of Mui V5.
                    // Going lighter here creates more contrast with 'disabled' fields.
                    backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.09)' : 'rgba(0, 0, 0, 0.06)',
                },
            },
            MuiOutlinedInput: {
                input: {
                    '&$disabled': {
                        color: '#595959',
                    },
                },
                root: {
                    '&$disabled $notchedOutline': {
                        borderColor: darkMode ? 'rgba(255, 255, 255, 0.65)' : 'rgba(0, 0, 0, 0.65)',
                        borderStyle: 'dotted',
                    },
                },
            },
        },
    };
    const V1Theme = themeCreator(themeV1Init);
    if (!V1Theme.overrides) {
        V1Theme.overrides = {};
    }

    V1Theme.overrides.MuiTableCell = {
        paddingNone: {
            paddingLeft: '3px',
            paddingRight: '3px',
        },
    };
    V1Theme.overrides.MuiInputLabel = {
        shrink: args.printMode
            ? undefined
            : {
                  [V1Theme.breakpoints.up('sm')]: {
                      top: '-.6em',
                  },
                  [V1Theme.breakpoints.down('sm')]: {
                      top: '-.7em',
                  },
              },
        outlined: {
            top: 0,
        },
        filled: {
            top: '-.1em',
        },
    };
    if (rebuildRWTStylesheet) {
        updateRwtStylesheet(V1Theme);
    }
    return V1Theme;
};

export default getInitialTheme;
