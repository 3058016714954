import { isFieldViewField, getDataTypeForFieldExpr, getAllFieldsFromView } from '../../../utils/viewConfigUtils/index';
import ViewConfig, { ViewField } from '../../../../../reducers/ViewConfigType';
import produce from 'immer';
import set from 'lodash/set';
import get from 'lodash/get';
import clone from 'clone';
import { hasCycle } from './hasCycle';

export const forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions =
    (viewConfig: ViewConfig, fieldDefinitions: ViewField[]) => (values: Record<string, unknown>) => {
        const booleanFields = fieldDefinitions.flatMap((f) => {
            if (
                isFieldViewField(f) &&
                getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'BOOLEAN' &&
                f.widgetType !== 'NULLABLE_BOOLEAN'
            ) {
                return [f.field];
            }
            return [];
        });
        const arrayFields = fieldDefinitions.flatMap((f) => {
            if (
                isFieldViewField(f) &&
                f.widgetType !== 'INLINE_MANY' &&
                (getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'VALUESETMANY' ||
                    getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANYMANY')
            ) {
                // Sometimes we expand the *Ids, but not the main data.
                // So let's just focus on *Ids
                return [f.field + 'Ids'];
            }
            return [];
        });

        const mutateValues = (toMutate: object) => {
            booleanFields.forEach((fexp) => {
                const value = get(values, fexp);
                if (typeof value !== 'boolean') {
                    set(toMutate, fexp, !!value);
                }
            });
            arrayFields.forEach((fexp) => {
                if (!get(values, fexp)) {
                    set(toMutate, fexp, []);
                }
            });
        };
        if (hasCycle(values)) {
            // slower, but prevents immer from stackoverflowing on cycles
            const clonedAndMutatedValues = (() => {
                const newValues = clone(values);
                mutateValues(newValues);
                return newValues;
            })();
            return clonedAndMutatedValues;
        }
        // now that we know it's not a cycle, we can use immer.
        // this should be a bit more efficient, since we don't clone the entire structure.
        return produce(values, (draftValues) => {
            booleanFields.forEach((fexp) => {
                const value = get(values, fexp);
                if (typeof value !== 'boolean') {
                    set(draftValues, fexp, !!value);
                }
            });
            arrayFields.forEach((fexp) => {
                if (!get(values, fexp)) {
                    set(draftValues, fexp, []);
                }
            });
            return draftValues;
        });
    };

const forceBooleanFieldsBooleanAndArrayFieldsArrays = (viewConfig: ViewConfig, viewName: string) => (values: {}) => {
    return forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions(
        viewConfig,
        getAllFieldsFromView(viewConfig, viewName),
    )(values);
};
export default forceBooleanFieldsBooleanAndArrayFieldsArrays;
