// This hasCycle implementation handles arrays as well.

export function hasCycle(obj, visited = new Set()) {
    if (obj && typeof obj === 'object') {
        if (visited.has(obj)) {
            return true;
        }

        visited.add(obj);

        if (Array.isArray(obj)) {
            // If it's an array, iterate over its elements
            for (const element of obj) {
                if (hasCycle(element, visited)) {
                    return true;
                }
            }
        } else {
            // If it's an object, iterate over its keys
            for (const key in obj) {
                if (hasCycle(obj[key], visited)) {
                    return true;
                }
            }
        }

        visited.delete(obj); // Clean up visited after traversal
    }
    return false;
}
