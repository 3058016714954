import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import flatten, { unflatten } from 'flat';
import isObject from 'lodash/isObject';
import updateDataOnRefChange from '../EntityFormContext/util/updatedDataOnSubRefChange';
import { combineFieldsReq, entityNullInitializeValues } from 'expressions/formValidation';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import applyDeltaToEntities from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData/ApplyDeltaToEntities';
import { getRefEntityName, getFieldSourceFromPath } from 'components/generics/utils/viewConfigUtils';
import fromEntries from 'util/fromentries';
import { EntityValidations } from 'reducers/entityValidationsReducer';
import { useMemo, useCallback } from 'react';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';
import { useDynamicKeyCachingEvaluators } from 'expressions/Provider/hooks/useKeyCachingEval';
import get from 'lodash/get';
import set from 'lodash/set';
import uniq from 'lodash/uniq';
import denormalizeWithoutRootFromPaths from './util/denormalizeWithoutRoot';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';

const removeEmptyObjects = (obj: unknown) => {
    if (isPlainObject(obj)) {
        return Object.fromEntries(
            Object.entries(obj)
                .map(([k, v]) => [k, removeEmptyObjects(v)])
                // we need to check after the recursion because objects might be newly empty.
                .filter(([k, v]) => {
                    return !(isPlainObject(v) && Object.keys(v).length === 0);
                }),
        );
    }
    return obj;
};

const removePlainObjectArrays = (obj: unknown) => {
    if (isPlainObject(obj)) {
        return Object.fromEntries(
            Object.entries(obj)
                .filter(([k, v]) => {
                    return !Array.isArray(v) || !v.some((item) => isPlainObject(item));
                })
                .map(([k, v]) => [k, removePlainObjectArrays(v)]),
        );
    }
    return obj;
};

const getReferencePaths = (values: {}, fields = false, acc = ''): string[] => {
    return Object.entries(values).flatMap(([path, value]) => {
        const currPath = acc ? `${acc}.${path}` : path;
        if (isObject(value) && !Array.isArray(value)) {
            return [currPath, ...getReferencePaths(value, fields, currPath)];
        }
        if (fields) {
            return [currPath];
        }
        return [];
    });
};

const getReferenceFields2 = (fields: string[]) => {
    return fields
        .filter((f) => f.includes('.'))
        .map((f) => (f.endsWith('Id') ? f.slice(0, -2) : f.endsWith('Ids') ? f.slice(0, -3) : f));
};
const getReferences2 = (fields: string[]) => {
    return uniq(
        fields
            .filter((f) => f.includes('.'))
            .map((f) => (f.endsWith('Id') ? f.slice(0, -2) : f.endsWith('Ids') ? f.slice(0, -3) : f))
            .map((f) => f.slice(0, f.lastIndexOf('.'))),
    );
};
type Common<A, B> = {
    [P in keyof A & keyof B]: A[P] | B[P];
};

/*
    given the fieldPaths on our data, filter the validation on the reference
*/
const fieldPathsRelatedToPath = (fieldPaths: string[], path: string) => {
    // get all paths that start with the 'path' e.g. 'firstName' if path is 'person' (given that some item was 'person.firstName').
    return fieldPaths.flatMap((p) => (p.startsWith(path) && p[path.length] === '.' ? [p.slice(path.length + 1)] : []));
};
const validationIsHitByReferencePath = (fieldPaths: string[], path: string) => (v: EntityValidations[0][0]) => {
    const res = (v.expansionsRequired || (v as any).fieldsRequired).some((fr) => {
        return fieldPathsRelatedToPath(fieldPaths, path).some((path) => path === fr || fr.startsWith(`${path}.`));
    });
    return res;
};

export const combineMultipleResults = (args: {
    results: {
        [path: string]: {
            [expression: string]: [any]; // expression to result
        };
        _root?: {
            [expression: string]: [any];
        };
    };
    expressionsToFields: {
        [path: string]: {
            [expression: string]: string[]; // fields
        };
    };
    expressionsToMessages: {
        [path: string]: {
            [expression: string]: string; // message
        };
    };
}) => {
    const { results, expressionsToFields, expressionsToMessages } = args;
    return Object.entries(results).reduce((prev, [key, evaldExps]) => {
        return {
            ...prev,
            ...fromEntries(
                Object.entries(evaldExps).flatMap(([exp, [result]]) => {
                    if (result) {
                        return [];
                    }
                    return expressionsToFields[key][exp].map(
                        (field) =>
                            [key !== '_root' ? `${key}.${field}` : field, expressionsToMessages[key][exp]] as [
                                string,
                                string,
                            ],
                    );
                }),
            ),
        };
    }, {});
};

export const NEW_ROW_CONTAINS_ERROR__MSG = 'A new row contains an error';
const getInvalidInlineRows = (values: {}) => {
    let res = {};
    const innerGetInvalidInlineRows = (values: {}, path = '') => {
        Object.entries(values).forEach(([k, v]) => {
            const newPath = path ? path + '.' + k : k;
            if (Array.isArray(v) && v.some((row) => row['__invalid'])) {
                res[newPath] = NEW_ROW_CONTAINS_ERROR__MSG;
            } else if (isPlainObject(v)) {
                innerGetInvalidInlineRows(v, newPath);
            }
        });
    };
    innerGetInvalidInlineRows(values);
    return res;
};

/*
    Although it is optional,
    the registeredFields parameter is very important:
    We might have values included as 'expansions' we needed in order to to perform validations or
    other expressions, even though they are not editable.

    Therefore, if person.firstName is only used in a validation, we don't need to pull in all
    validations on Person (lets say we are on PersonAddress), because the field is not editable.
    However if the field is editable, we do need to pull in all validations on personAddress that use it,
    
    To do this, we pass 'registeredFields' - a list of all potentially editable fields.
    That way we prevent bugs where we are getting validation errors on fields that we don't control
*/
const useValidation = (params: {
    values: {};
    type?: 'error' | 'warn' | '*';
    resource: string;
    registeredFields?: string[];
    extraValidations?: EntityValidations[0];
    adhocVariablesContext?: Record<string, unknown>;
}) => {
    const { resource, values, registeredFields, extraValidations, type, adhocVariablesContext } = params;

    const filterValidations = useCallback(
        (validations: EntityValidations[0]) =>
            validations.filter(({ level = 'error' }) => !type || type === '*' || level === type),
        [type],
    );
    const validations = useAppSelector((state: RootState) => state.entityValidations);
    const viewConfig = useViewConfig();
    const entities = useEntities();
    const rootValidations = useMemo(
        () => filterValidations([...(validations[resource] ?? []), ...(extraValidations ?? [])]),
        [validations, resource, extraValidations, filterValidations],
    );

    // values restricted to those essential
    // (if we pass arrays of many-relationships, this is going to make the applyDeltaToEntities
    // take forever. So lets filter to just the data needed.)
    const restrictedValues = registeredFields
        ? (() => {
              let res = {}; // <- include id entityType entityVersion etc
              const fieldsWeNeed = (() => {
                  const referencePaths: { [path: string]: true } = {};
                  registeredFields.forEach((rf) => {
                      if (rf.includes('.')) {
                          const referencePath = rf.slice(0, rf.lastIndexOf('.'));
                          referencePaths[referencePath] = true;
                      }
                  });
                  let _fieldsWeNeed = [
                      ...registeredFields,
                      'id',
                      'entityType',
                      'entityVersion',
                      ...Object.keys(referencePaths).map((r) => `${r}.id`),
                  ];
                  return _fieldsWeNeed;
              })();
              fieldsWeNeed.forEach((rgf) => {
                  const valueAtRegisteredFieldpath = get(values, rgf);
                  if (typeof valueAtRegisteredFieldpath !== 'undefined') {
                      set(res, rgf, valueAtRegisteredFieldpath);
                  }
              });
              return res;
          })()
        : values;

    const valsUpdatedForChangedRefs: Record<string, any> = useMemo(() => {
        return fromNullable(rootValidations)
            .map((_validations) => {
                const expressionFields = Object.values(_validations).reduce((prev, curr) => {
                    return prev.concat(curr.dataPaths || curr.expansionsRequired || (curr as any).fieldsRequired);
                }, []);
                return updateDataOnRefChange(
                    viewConfig,
                    resource,
                    entities,
                    Object.entries(flatten(restrictedValues)),
                    expressionFields,
                );
            })
            .map((entries) => unflatten(Object.assign({}, restrictedValues, ...entries.map(([k, v]) => ({ [k]: v })))))
            .getOrElse(restrictedValues);
    }, [entities, resource, viewConfig, restrictedValues, rootValidations]);

    // This is going to be mutated.
    const valsUpdatedForchangedRefsExceptManys = useMemo(() => {
        // if we call flatten() and some nodes are empty objects, those values will be set as empty objects (rather than not being included), which we don't want.

        // We originally removed all arrays for performance
        // (see https://src.casetivity.com/ssg/casetivity-front-end/-/commit/e56972685dd6be22683cd8c8afd3bb7af30485b9)
        //  but this prevented us from using valueset-manys in validations.
        // (see https://strategicsolutionsgroup.atlassian.net/browse/FISH-4220)
        // So instead, removing arrays of objects, but leaving ids.
        // If we ever need to validate manies from a root record (e.g. inline-many) we should revisit this.
        return removeEmptyObjects(removePlainObjectArrays(valsUpdatedForChangedRefs));
    }, [valsUpdatedForChangedRefs]);

    const entityBagWithDeltaFromValues = useMemo(() => {
        return applyDeltaToEntities(
            viewConfig,
            entities,
            flatten(valsUpdatedForchangedRefsExceptManys),
            {
                id: valsUpdatedForChangedRefs.id,
                entityType: resource,
            },
            false,
        );
    }, [viewConfig, entities, valsUpdatedForChangedRefs, valsUpdatedForchangedRefsExceptManys, resource]);

    // paths to all fields (i.e. including leafs. e.g. person.firstName);
    const fieldPaths = useMemo(
        () =>
            registeredFields
                ? getReferenceFields2(registeredFields)
                : getReferencePaths(valsUpdatedForChangedRefs, true),
        [valsUpdatedForChangedRefs, registeredFields],
    );

    // paths across data (i.e. without leaf nodes. e.g. person)
    const referencePaths = useMemo(
        () => (registeredFields ? getReferences2(registeredFields) : getReferencePaths(valsUpdatedForChangedRefs)),
        [valsUpdatedForChangedRefs, registeredFields],
    );
    /* 
        using referencePaths, we will create our caching evaluators.
    */
    const validationExpressionsOnReferences = useMemo(() => {
        const validationsWeNeedInEvaluator: [
            string, // path
            { reference: string; validations: EntityValidations[0] },
        ][] = referencePaths.flatMap((path) => {
            const reference = getRefEntityName(viewConfig, resource, path, 'TRAVERSE_PATH');
            const validationsOnReference = validations[reference];
            if (validationsOnReference) {
                const relevantValidationsOnReference = filterValidations(validationsOnReference).filter(
                    validationIsHitByReferencePath(fieldPaths, path),
                );
                if (relevantValidationsOnReference.length > 0) {
                    return [
                        [
                            path,
                            {
                                reference,
                                validations: relevantValidationsOnReference,
                            },
                        ],
                    ] as [
                        string,
                        {
                            reference: string;
                            validations: EntityValidations[0];
                        },
                    ][];
                }
                return [];
            }
            return [];
        });
        return validationsWeNeedInEvaluator;
    }, [referencePaths, fieldPaths, resource, validations, viewConfig, filterValidations]);

    const expressions = useMemo(() => {
        return validationExpressionsOnReferences.reduce(
            (prev, [path, { reference, validations }]) => {
                prev[path] = fromEntries(validations.map((v) => [v.expression, [v.expression]] as [string, [string]]));
                return prev;
            },
            {} as {
                [path: string]: {
                    [expression: string]: [string];
                };
            },
        );
    }, [validationExpressionsOnReferences]);

    const expressionsToFields = useMemo(() => {
        return validationExpressionsOnReferences.reduce(
            (prev, [path, { reference, validations }]) => {
                prev[path] = fromEntries(
                    validations.map((v) => [
                        v.expression,
                        combineFieldsReq(v.expansionsRequired || (v as any).fieldsRequired, v.valuesetFieldsRequired),
                    ]),
                );
                return prev;
            },
            {
                _root: fromEntries(
                    (rootValidations || []).map((v) => [
                        v.expression,
                        combineFieldsReq(v.expansionsRequired || (v as any).fieldsRequired, v.valuesetFieldsRequired),
                    ]),
                ),
            } as {
                [path: string]: {
                    [expression: string]: string[];
                };
                _root: {
                    [expression: string]: string[];
                };
            },
        );
    }, [validationExpressionsOnReferences, rootValidations]);
    const expressionsToMessages = useMemo(() => {
        return validationExpressionsOnReferences.reduce(
            (prev, [path, { reference, validations }]) => {
                prev[path] = fromEntries(validations.map((v) => [v.expression, v.message]));
                return prev;
            },
            {
                _root: fromEntries((rootValidations || []).map((v) => [v.expression, v.message])),
            } as {
                [path: string]: {
                    [expression: string]: string;
                };
                _root: {
                    _expression: string;
                };
            },
        );
    }, [validationExpressionsOnReferences, rootValidations]);

    const expressionMappings = {
        ...expressions,
        _root: fromEntries((rootValidations || []).map((v) => [v.expression, [v.expression]] as [string, [any]])),
    };

    const r = useDynamicKeyCachingEvaluators(expressionMappings, entityBagWithDeltaFromValues, adhocVariablesContext);

    return useCallback(() => {
        /**
         * Only newly created entities patch
         * (nested objects lacking ids)
         * we will patch this onto 'denormalized'
         * because our denormalized entity bag can only consist of real entities.
         */
        const entitiesToCreate = (() => {
            const acc: {
                [path: string]: {};
            } = {};

            const traverseAccNewObjects = (obj: {}, path = '') => {
                Object.entries(obj).forEach(([k, v]) => {
                    if (typeof v === 'object' && v !== null && !Array.isArray(v)) {
                        // we found an object; lets make sure it's not an existing entity
                        const isNotExistingEntity = !v['id'] && !obj[`${k}Id`];
                        const pathToHere = path ? path + '.' + k : k;
                        if (isNotExistingEntity) {
                            set(acc, pathToHere, v);
                        } else {
                            traverseAccNewObjects(v, pathToHere);
                        }
                    }
                });
            };
            traverseAccNewObjects(valsUpdatedForchangedRefsExceptManys);
            return acc;
        })();
        const pathsToExpand = (rootValidations || [])
            .flatMap((v) => v.expansionsRequired || (v as any).fieldsRequired)
            .flatMap((fr: string) => {
                if (fr.includes('.')) {
                    return [fr.slice(0, fr.lastIndexOf('.'))];
                }
                return [];
            });

        const denormalized = (() => {
            if (
                valsUpdatedForChangedRefs.id &&
                entityBagWithDeltaFromValues[resource]?.[valsUpdatedForChangedRefs.id]
            ) {
                return denormalizeEntitiesByPaths(
                    entityBagWithDeltaFromValues,
                    pathsToExpand,
                    viewConfig,
                    resource,
                    valsUpdatedForChangedRefs.id,
                );
            }
            return denormalizeWithoutRootFromPaths(
                resource,
                valsUpdatedForChangedRefs,
                viewConfig,
                entityBagWithDeltaFromValues,
                pathsToExpand,
            );
        })();

        const denormalizedAndPatchedWithNewObjects = merge({}, entitiesToCreate, denormalized);

        const result: {
            [path: string]: {
                [expression: string]: [any];
            };
            _root?: {
                [expression: string]: [any];
            };
        } = {};
        validationExpressionsOnReferences.forEach(([path, { reference, validations: vs }]) => {
            // id of entity in our store we need to test
            const referenceId =
                valsUpdatedForChangedRefs[`${path}Id`] ||
                (valsUpdatedForChangedRefs[path] && valsUpdatedForChangedRefs[path].id) ||
                get(denormalized, `${path}Id`);

            const nestedValidationDataPaths = vs.flatMap((vs) => vs.dataPaths || (vs as any).fieldsRequired);
            const valueSetsUsedInValidations = fromEntries(
                vs.flatMap((vs) => Object.entries(vs.valuesetFieldsRequired)),
            );
            if (referenceId) {
                const fieldsUsedInValidations = vs.flatMap((vs) => vs.expansionsRequired || (vs as any).fieldsRequired);
                const fieldsToExpandOnModifiedRecord = fieldsUsedInValidations.map((f) => {
                    const source = getFieldSourceFromPath(viewConfig, reference, f);
                    return source;
                });
                const modifiedRecordValues = entityNullInitializeValues(
                    denormalizeEntitiesByPaths(
                        entityBagWithDeltaFromValues,
                        fieldsToExpandOnModifiedRecord,
                        viewConfig,
                        reference,
                        referenceId,
                    ),
                    nestedValidationDataPaths,
                    valueSetsUsedInValidations,
                    entityBagWithDeltaFromValues,
                );
                result[path] = r[path](modifiedRecordValues);
            } else {
                const relatedRecord = get(denormalizedAndPatchedWithNewObjects, path);
                if (!isPlainObject(relatedRecord)) {
                    return;
                }
                const modifiedRecordValues = entityNullInitializeValues(
                    relatedRecord,
                    nestedValidationDataPaths,
                    valueSetsUsedInValidations,
                    entityBagWithDeltaFromValues,
                );
                result[path] = r[path](modifiedRecordValues);
            }
        });

        const nullInitializedValues = entityNullInitializeValues(
            denormalizedAndPatchedWithNewObjects,
            (rootValidations || []).flatMap((v) => v.dataPaths || (v as any).fieldsRequired),
            fromEntries((rootValidations || []).flatMap((vs) => Object.entries(vs.valuesetFieldsRequired))),
            entityBagWithDeltaFromValues,
        );
        result._root = r._root(nullInitializedValues);
        const res = combineMultipleResults({
            results: result,
            expressionsToFields,
            expressionsToMessages,
        });

        // i.e. get paths to any inlineMany fields having added rows which contain '__invalid' keys.
        const invalidInlineRows = getInvalidInlineRows(valsUpdatedForChangedRefs);
        // Any JSON strings containing __invalid will prevent submission.
        // This be caused triggered by dynamic/bpm form fields having invalid validations.
        const invalidFields = Object.fromEntries(
            Object.entries(flatten(valsUpdatedForChangedRefs))
                .filter(([k, v]) => {
                    if (typeof v === 'string' && v.startsWith('{') && v.endsWith('}')) {
                        try {
                            const parsedDynamicFormValue = JSON.parse(v);
                            return !!parsedDynamicFormValue?.__invalid;
                        } catch (e) {
                            return false;
                        }
                    }
                    return false;
                })
                .map(([k]) => [k, 'Dynamic form contains an error']),
        );

        /**
         * There is potential information loss when unflattening.
         * For example, res is
         * "currentAddress": "City is Required",
         * "currentAddressId": "City is Required",
         * "currentAddress.communityId": "City is Required"
         *
         * the result is just
         * "currentAddress": "City is Required"
         * "currentAddressId": "City is Required",
         *
         * by adding { "overwrite": true }
         * we can make it
         * "currentAddress": {
         *   "communityId": "City is Required"
         * }
         * "currentAddressId": "City is Required",
         */

        return unflatten({ ...res, ...invalidFields, ...invalidInlineRows }, { overwrite: true });
    }, [
        entityBagWithDeltaFromValues,
        expressionsToFields,
        expressionsToMessages,
        r,
        resource,
        validationExpressionsOnReferences,
        valsUpdatedForChangedRefs,
        valsUpdatedForchangedRefsExceptManys,
        viewConfig,
        rootValidations,
    ]);
};
export default useValidation;
