import React, { useContext, useReducer, useEffect, useCallback, useMemo, useRef } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import useStatus from 'util/hooks/useStatus';
import { refreshContext } from 'components/generics/form/refreshContext';
import GenericEdit from '../genericEdit/index2';
import ContentCreate from '@material-ui/icons/Create';
import GenericShow from '../genericShow';
import { getCustomViewName } from '../utils/viewConfigUtils';
import Toolbar from '../form/Toolbar.aor';
import CloseButton from 'fieldFactory/popovers/PopoverCloseButton';
import SaveButton from '../button/SaveButton';
import { ShowActions, EditActions } from '../viewActions/ActionsWithOverrides';
import Button from '@material-ui/core/Button';
import InlineDeleteButton from '../button/InlineDeleteButton';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogTransitionProps from 'util/DialogTransitionProps';
import { UpdateParams } from 'sideEffect/crud/update/actions';
import isOffline from 'util/isOffline';
import Dialog from '@mui/material/Dialog';

interface ShowActionProps {
    showActionChildren?: React.ReactElement<{}>;
    editViewName: string | -1;
    viewName: string;
    hasEdit?: boolean;
    handleEditOpen: () => void;
    id: string;
}
/*
    We create an intermediate component here because the Show view will copy props onto it.
*/
const EntityInspectShowActions: React.FunctionComponent<ShowActionProps> = (props) => {
    const { hasEdit = false, viewName, id, editViewName } = props;
    return (
        <ShowActions
            id={id}
            viewName={viewName}
            hasDefaultEdit={false} // we will override this with our own
        >
            {hasEdit && editViewName !== -1 ? (
                <Button data-minAccessLevel={3} color="primary" onClick={props.handleEditOpen}>
                    Edit&nbsp;
                    <ContentCreate />
                </Button>
            ) : null}
            {props.showActionChildren && React.cloneElement(props.showActionChildren, props)}
        </ShowActions>
    );
};

export interface EntityInspectProps {
    interceptSubmit?: (params: UpdateParams) => void;
    showActionChildren?: React.ReactElement<{}>;
    openTo?: 'edit' | 'show';
    onMergeOccurred?: (args: { oldId: string; newId: string }) => void;
    reference: string;
    formId?: string;
    editViewName?: string | -1;
    showViewName?: string | -1;
    renderComponent: (renderProps: {
        formId: string;
        reference: string;
        keyForReload: number;
        selectId: (id: string | number | null) => void;
        onRowSelect: <T extends { id: string }>(selected: T[]) => void;
        selectedId: number | string | null;
    }) => JSX.Element;
    config?: string;
    renderAboveEditForm?: () => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
    onDialogClose?: (needsRefresh: boolean) => void;
}

type ViewOpenState =
    | {
          type: 'CLOSED';
          keyForReload: number;
      }
    | {
          type: 'OPEN';
          viewType: 'EDIT' | 'SHOW';
          id: string;
          keyForReload: number;
      };
type ViewOpenActions =
    | {
          type: 'SWITCH_TO';
          payload: {
              viewType: 'EDIT' | 'SHOW';
              id?: string;
          };
      }
    | {
          type: 'OPEN';
          payload: {
              id: string;
              viewType: 'EDIT' | 'SHOW';
          };
      }
    | {
          type: 'CLOSE';
      }
    | {
          type: 'MOVED_TO_NEW_ID';
          payload: {
              newId: string;
          };
      }
    | {
          type: 'CLOSE_WITH_SAVE';
      };
const viewOpenReducer = (state: ViewOpenState, action: ViewOpenActions): ViewOpenState => {
    switch (action.type) {
        case 'CLOSE':
            return {
                ...state,
                type: 'CLOSED',
            };
        case 'CLOSE_WITH_SAVE':
            return {
                ...state,
                keyForReload: state.keyForReload + 1,
                type: 'CLOSED',
            };
        case 'SWITCH_TO': {
            if (state.type === 'OPEN') {
                return {
                    ...state,
                    type: 'OPEN',
                    viewType: action.payload.viewType,
                    id: action.payload.id || state.id,
                };
            } else {
                return state;
            }
        }
        case 'OPEN':
            return {
                ...state,
                type: 'OPEN',
                viewType: action.payload.viewType,
                id: action.payload.id,
            };
        case 'MOVED_TO_NEW_ID':
            if (state.type === 'OPEN') {
                return {
                    ...state,
                    id: action.payload.newId,
                };
            }
            return state;
    }
};

/**
 * Our multiselects/lists don't refresh while another popup is opened on top of them
   (to prevent closing out by accident)
   so we will track if we should refresh _after_ closing.
 */
const useMaybeFullRefreshAfterClose = (isClosed: boolean, refresh: (e?: Event, fullRefresh?: boolean) => void) => {
    const shouldFullRefreshAfterCloseRef = useRef(false);

    const cancelDelayedRefreshOnUnmountRef = useRef<NodeJS.Timeout>();
    useEffect(() => {
        return () => {
            clearTimeout(cancelDelayedRefreshOnUnmountRef.current);
        };
    }, []);
    const delayedRefresh = useCallback(() => {
        cancelDelayedRefreshOnUnmountRef.current = setTimeout(() => {
            refresh(null, true); // 'full' refresh, to refresh x-manys
        }, 333);
    }, [refresh]);
    useEffect(() => {
        if (isClosed) {
            if (!shouldFullRefreshAfterCloseRef.current) {
                return;
            }
            delayedRefresh();
            shouldFullRefreshAfterCloseRef.current = false;
        }
    }, [isClosed, delayedRefresh]);

    return shouldFullRefreshAfterCloseRef;
};
const EntityInspect = (props: EntityInspectProps) => {
    const { onMergeOccurred, renderComponent, reference, formId: _formId, interceptSubmit } = props;
    const formId = _formId?.split('.')?.join('_~_');
    const viewConfig = useViewConfig();
    const refresh = useContext(refreshContext);
    const [viewOpen, dispatchViewOpen] = useReducer(viewOpenReducer, { type: 'CLOSED', keyForReload: 1 });
    const status = useStatus(props.reference, viewOpen.type === 'OPEN' ? viewOpen.id : undefined);
    const wasMovedToId = status && status._type === 'moved' ? status.newId : null;
    const selectedId = viewOpen.type === 'OPEN' ? viewOpen.id : undefined;

    const shouldFullRefreshAfterCloseRef = useMaybeFullRefreshAfterClose(viewOpen.type === 'CLOSED', refresh);

    useEffect(() => {
        if (wasMovedToId) {
            if (onMergeOccurred) {
                onMergeOccurred({
                    newId: wasMovedToId,
                    oldId: selectedId,
                });
            }
            dispatchViewOpen({
                type: 'MOVED_TO_NEW_ID',
                payload: { newId: wasMovedToId },
            });
            refresh();
        }
    }, [wasMovedToId, selectedId, onMergeOccurred, dispatchViewOpen, refresh]);
    const onRowSelect = useCallback(
        (selectedRecords: { id: string }[], data?: any, openTo?: 'edit') => {
            console.log('onRowSelect', selectedRecords);
            dispatchViewOpen({
                type: 'OPEN',
                payload: {
                    viewType: openTo === 'edit' || props.openTo === 'edit' ? 'EDIT' : 'SHOW',
                    id: selectedRecords[0] && selectedRecords[0].id,
                },
            });
        },
        [dispatchViewOpen, props.openTo],
    );
    const selectId = useCallback(
        (id: string) => {
            console.log('selectId', id);
            dispatchViewOpen({
                type: 'OPEN',
                payload: {
                    viewType: props.openTo === 'edit' ? 'EDIT' : 'SHOW',
                    id,
                },
            });
        },
        [dispatchViewOpen, props.openTo],
    );
    const { onDialogClose } = props;
    const handleClose = useCallback(
        (e?: React.MouseEvent<HTMLButtonElement>) => {
            if (e) {
                e.preventDefault();
                e.stopPropagation();
            }
            onDialogClose?.(shouldFullRefreshAfterCloseRef.current);
            dispatchViewOpen({ type: 'CLOSE' });
        },
        [dispatchViewOpen, onDialogClose, shouldFullRefreshAfterCloseRef],
    );
    const handleSwitchToShow = useCallback(() => {
        dispatchViewOpen({
            type: 'SWITCH_TO',
            payload: {
                viewType: 'SHOW',
            },
        });
    }, [dispatchViewOpen]);
    const handleSwitchToEdit = useCallback(() => {
        dispatchViewOpen({
            type: 'SWITCH_TO',
            payload: {
                viewType: 'EDIT',
            },
        });
    }, [dispatchViewOpen]);
    const handleDeleteSuccess = useCallback(() => {
        if (refresh) {
            refresh();
        }
        dispatchViewOpen({ type: 'CLOSE_WITH_SAVE' });
    }, [refresh, dispatchViewOpen]);

    const handleOnSaveEdit = useCallback(
        (id) => {
            shouldFullRefreshAfterCloseRef.current = true;
            if (props.showViewName === -1) {
                dispatchViewOpen({
                    type: 'CLOSE_WITH_SAVE',
                });
            } else {
                dispatchViewOpen({
                    type: 'OPEN',
                    payload: {
                        id,
                        viewType: 'SHOW',
                    },
                });
            }
            refresh();
        },
        [dispatchViewOpen, props.showViewName, refresh, shouldFullRefreshAfterCloseRef],
    );

    const match = useMemo(() => {
        return {
            isExact: true,
            params: {
                id: selectedId,
                resourceBasePath: `/${reference}`,
            },
        };
    }, [reference, selectedId]);
    const DialogView = (
        <Dialog
            maxWidth={false}
            fullWidth={true}
            TransitionProps={DialogTransitionProps}
            open={viewOpen.type === 'OPEN'}
            onClose={handleClose}
        >
            <div>
                {viewOpen.type === 'OPEN' &&
                    (viewOpen.viewType === 'EDIT' ? (
                        <GenericEdit
                            onNeedsRefresh={() => {
                                // we need to say "We need a refresh" if some nested thing in there saved,
                                // e.g. popped up _inside_ the edit/show, but the edit inside didn't save.
                                shouldFullRefreshAfterCloseRef.current = true;
                            }}
                            evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                            renderAboveForm={props.renderAboveEditForm}
                            interceptSubmit={interceptSubmit}
                            noRedirectOnIdChange={true}
                            formId={`${reference}-Edit-${formId}`}
                            disallowClickAwayNavigation={props.disallowClickAwayNavigation}
                            actions={
                                <EditActions
                                    id={selectedId}
                                    viewName={props.editViewName as string}
                                    hasDefaultShow={false} // use our provided show button
                                    hasDefaultDelete={false} // use provided delete
                                >
                                    {props.showViewName !== -1 && (
                                        <Button color="primary" onClick={handleSwitchToShow}>
                                            View <ImageEye />
                                        </Button>
                                    )}
                                    {!isOffline() && (
                                        <InlineDeleteButton
                                            data-minAccessLevel={5}
                                            id={selectedId}
                                            resource={props.reference}
                                            renderIcon={({ handleClick, classes }) => (
                                                <Button color="secondary" onClick={handleClick} className={classes.del}>
                                                    Delete <DeleteIcon />
                                                </Button>
                                            )}
                                            onDeleteSuccess={handleDeleteSuccess}
                                        />
                                    )}
                                </EditActions>
                            }
                            redirect={false}
                            viewName={
                                props.editViewName ||
                                getCustomViewName('EDIT')(props.reference, viewConfig, props.config)
                            }
                            onSaveCb={!interceptSubmit ? handleOnSaveEdit : () => {}}
                            resource={reference}
                            id={selectedId}
                            toolbar={
                                <Toolbar>
                                    <SaveButton />
                                    <CloseButton handleClose={handleClose} />
                                </Toolbar>
                            }
                        />
                    ) : props.showViewName === -1 ? null : (
                        <GenericShow
                            onNeedsRefresh={() => {
                                shouldFullRefreshAfterCloseRef.current = true;
                            }}
                            disallowClickAwayNavigation={props.disallowClickAwayNavigation}
                            evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
                            actions={
                                <EntityInspectShowActions
                                    id={selectedId}
                                    handleEditOpen={handleSwitchToEdit}
                                    editViewName={props.editViewName}
                                    viewName={props.showViewName}
                                    showActionChildren={props.showActionChildren}
                                />
                            }
                            resource={reference}
                            match={match}
                            formId={`${reference}-show-${formId}`}
                            viewName={
                                props.showViewName ||
                                getCustomViewName('SHOW')(props.reference, viewConfig, props.config)
                            }
                            toolbar={
                                <Toolbar>
                                    <CloseButton handleClose={handleClose} />
                                </Toolbar>
                            }
                        />
                    ))}
            </div>
        </Dialog>
    );
    return (
        <React.Fragment>
            {renderComponent({
                formId,
                reference,
                keyForReload: viewOpen.keyForReload,
                onRowSelect,
                selectedId,
                selectId,
            })}
            {DialogView}
        </React.Fragment>
    );
};
export default EntityInspect;
