import ViewConfig, { View, ViewField } from 'reducers/ViewConfigType';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import {
    getFieldSourceFromPath,
    isFieldToAppendIdToSource,
    isFieldToAppendIdsToSource,
    isFieldViewField,
    isInlineManyViewField,
    isRefManyField,
} from 'components/generics/utils/viewConfigUtils';

interface Options {
    noTabs?: boolean;
    includeVisibilityExpressions?: boolean;
    includeConceptExpressions?: boolean;
    includeEditabilityExpressions?: boolean;
    includeActionButtons?: boolean;
    noXManys?: boolean;
    inlineManys?: boolean;
    viewConfig: ViewConfig;
}

const getFieldEntriesFromView = (view: View) => {
    const fields = Object.entries(view.fields);
    Object.values(view.tabs ?? {}).forEach((tab) => {
        Object.entries(tab.fields)?.forEach((entry) => {
            fields.push(entry);
        });
    });
    return fields;
};

const entries = <K extends 'visibleField' | 'editableField' | 'conceptIdsForFields'>(
    view: View,
    noTabs: boolean,
    key: K,
    viewConfig: ViewConfig,
): EntityViewConfig[K] => {
    const ourViewFields = getFieldEntriesFromView(view);
    const ourConfig: EntityViewConfig = view.config ? JSON.parse(view.config) : null;
    if (ourConfig?.[key]) {
        const conf = ourConfig[key];
        const flattenedConfig = Array.isArray(conf)
            ? conf.reduce((prev, curr) => {
                  return Object.assign(prev, curr);
              }, {})
            : ourConfig[key];
        const result = Object.fromEntries(
            Object.entries(flattenedConfig).filter(([k]) => {
                const fieldSource = getFieldSourceFromPath(viewConfig, view.entity, k);
                if (noTabs) {
                    return view.fields[fieldSource];
                } else {
                    return ourViewFields.some(([fieldKey, field]) => {
                        if (isFieldViewField(field)) {
                            if (isFieldToAppendIdToSource(viewConfig)(view.entity)(field) && `${fieldKey}Id` === k) {
                                // We're having a scenario where 'Id' is being appended to the widget id (NOT the field path). It's a bit weird... but let's work around it.
                                return true;
                            }
                            if (isFieldToAppendIdsToSource(viewConfig)(view.entity)(field) && `${fieldKey}Ids` === k) {
                                // Same as above, but with *Ids
                                return true;
                            }
                            if (field.field === fieldSource) {
                                return true;
                            }
                        }
                        return fieldKey === fieldSource;
                    });
                }
            }),
        ) as EntityViewConfig[K];

        if (key === 'visibleField' || key === 'editableField') {
            return [result];
        }
        return result;
    }
    return undefined;
};

const produceCombinedView =
    (options: Options) =>
    (ourView: View, theirView: View): View => {
        const theirConfig = (theirView.config ? JSON.parse(theirView.config) : null) as EntityViewConfig;

        const visibleField = options.includeVisibilityExpressions
            ? entries(ourView, options.noTabs, 'visibleField', options.viewConfig)
            : theirConfig?.visibleField;
        const editableField = options.includeEditabilityExpressions
            ? entries(ourView, options.noTabs, 'editableField', options.viewConfig)
            : theirConfig?.editableField;
        const conceptIdsForFields = options.includeConceptExpressions
            ? entries(ourView, options.noTabs, 'conceptIdsForFields', options.viewConfig)
            : theirConfig?.conceptIdsForFields;

        const entityActions = options.includeActionButtons
            ? ((ourView.config ? JSON.parse(ourView.config) : null) as EntityViewConfig)?.entityActions
            : theirConfig?.entityActions;

        const ourConf = ourView.config ? (JSON.parse(ourView.config) as EntityViewConfig) : undefined;
        const { defaultValueExpressions, conditionalDefaultValueExpressions, calcValueExpressions } = ourConf ?? {};
        const newConfig = {
            ...theirConfig,
            visibleField,
            editableField,
            conceptIdsForFields,
            entityActions,
            defaultValueExpressions,
            conditionalDefaultValueExpressions,
            calcValueExpressions,
        };

        const filterTransformFields = (fields: { [key: string]: ViewField }) => {
            const filtered = options.noXManys
                ? Object.fromEntries(
                      Object.entries(fields).filter(([, f]) => {
                          if (!isFieldViewField(f)) {
                              return true;
                          }
                          const isRefMany = isRefManyField(options.viewConfig, f.entity, f.field, 'POP_LAST');
                          const isTrueRefMany = isRefMany && !isInlineManyViewField(f);

                          return options.inlineManys ? !isTrueRefMany : !isRefMany;
                      }),
                  )
                : fields;
            const mappedToNewTypes = Object.fromEntries(
                Object.entries(filtered).map(([k, f]) => {
                    if (!options.noXManys && !options.inlineManys && isInlineManyViewField(f)) {
                        // we are migrating to show and create
                        return [
                            k,
                            {
                                ...f,
                                widgetType: 'MULTISELECT' as const,
                            },
                        ];
                    }
                    return [k, f];
                }),
            );
            return mappedToNewTypes;
        };
        const fields = filterTransformFields(ourView.fields);

        return {
            ...theirView,
            fields,
            tabs: options.noTabs
                ? undefined
                : ourView.tabs &&
                  Object.fromEntries(
                      Object.entries(ourView.tabs).map(([k, v]) => [
                          k,
                          {
                              ...v,
                              fields: v.fields && filterTransformFields(v.fields),
                          },
                      ]),
                  ),
            config: JSON.stringify(newConfig),
        };
    };

export default produceCombinedView;
