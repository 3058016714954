import * as React from 'react';
import { ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import WithErrorBoundary from '../WithErrorBoundary';
import memoize from 'lodash/memoize';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { ViewField } from 'reducers/ViewConfigType';
import { isExpressionViewField } from 'components/generics/utils/viewConfigUtils';
import AccordionComponent from 'templatePage/templatableComponents/Accordian';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { get } from 'lodash';
import { FlowableField } from 'fieldFactory/translation/types';

const UncheckedGrid: any = Grid;
/*
    span	the number of cells,0 corresponds to display: none
    offset	the number of cells to the left of the grid spacing, no cell in grid spacing
    order	col number in the row
    xs	<576px and also default setting, could be a span value or a object contain above props
    sm	≥576px, could be a span value or a object contain above props
    md	≥768px, could be a span value or a object contain above props
    lg	≥992px, could be a span value or a object contain above props
    xl	≥1200px, could be a span value or a object contain above props
    https://github.com/evgenyrodionov/flexboxgrid2
*/

const encloseInARow = (cols, flexStart = false) => {
    return (
        <Grid item={true} xs={12}>
            <Grid container={true} spacing={0}>
                {cols}
            </Grid>
        </Grid>
    );
};

const getGridStyle = memoize((rowMargin: 'normal' | 'none', alignSelf: boolean) => {
    const rowMarginStyle = rowMargin === 'normal' ? { marginTop: '16px', marginBottom: '8px' } : {};
    return alignSelf ? { alignSelf: 'flex-start', ...rowMarginStyle } : rowMarginStyle;
});

const displayField = <FieldProps extends { renderDesc?: () => React.ReactNode; dropdownPosition?: 'above' | 'below' }>(
    field: React.ReactElement<FieldProps>,
    dropdownsFlipUp?: boolean,
) => (
    <WithErrorBoundary>
        <div>
            {dropdownsFlipUp ? React.cloneElement(field, { dropdownPosition: 'above' } as Partial<FieldProps>) : field}
            {field.props.renderDesc ? (
                <div style={{ paddingLeft: '10px', marginTop: '-5px' }}>{field.props.renderDesc()}</div>
            ) : null}
        </div>
    </WithErrorBoundary>
);
const encloseInACol = (
    field,
    dropdownsFlipUp = false,
    smSize: number | null = 12,
    xsSize: number | null = smSize,
    rowMargin = 'normal',
) => {
    if (field === undefined) {
        return <Grid item={true} xs={12} sm={12} md={12} lg={12} />;
    }
    if (field.props.dontShowCol) {
        return <div style={{ display: 'none' }} />;
    }
    return (
        <UncheckedGrid
            item={true}
            xs={(xsSize === null ? field.props.span : xsSize) || false}
            sm={(smSize === null ? field.props.span : smSize) || false}
            md={field.props.span || false}
            lg={field.props.span || false}
            xl={field.props.span || false}
            style={getGridStyle(rowMargin as 'normal' | 'none', field.props.alignSelf)}
        >
            {displayField(field, dropdownsFlipUp)}
        </UncheckedGrid>
    );
};

const encloseInAGrid = (rows) => (
    <div>
        <Grid alignItems="flex-end" container={true} spacing={0}>
            {rows.map((row, key) => React.cloneElement(row, { key }))}
        </Grid>
    </div>
);

const orderFieldsByCol = (fields) => {
    return orderBy(fields, 'props.column', 'asc');
};

interface RGridProps {
    smSize?: number | null;
    xsSize?: number | null;
    flexStart?: boolean;
    fields?: ReactElement<{
        row?: number;
        span?: number;
    }>[];
    lastRowDropdownsFlipUp?: boolean;
    rowMargin?: 'none' | 'normal';
    printRowMargin?: string | number;
}

const sortFieldGroupsByRow = (fields) => {
    let sortedFields = fields;
    if (fields) {
        sortedFields = fields
            .filter((field) => field != null)
            .sort((field1, field2) => {
                if (!field1.props.row && !field2.props.row) {
                    return 0;
                }
                if (!field1.props.row && field2.props.row) {
                    return 1;
                }
                if (field1.props.row && !field2.props.row) {
                    return -1;
                }
                if (field1.props.row && field2.props.row) {
                    if (field1.props.row < field2.props.row) {
                        return -1;
                    }
                    if (field1.props.row > field2.props.row) {
                        return 1;
                    }
                }
                return 0;
            });
    }

    return groupBy(sortedFields, 'props.row');
};

const customGet = (object: unknown, path: string) => {
    if (path === '') {
        // we need some way of pointing to the current object, so let's make it the empty string.
        return object;
    }
    return get(object, path);
};

type ElementTree = Array<React.ReactElement<any> | ElementTree>;
/**
 *
 * Technically we're building a tree.
 * This is how we append to the current 'accordion' group, allowing nested accordions.
 * We always append to 'getHead()', popping when we leave an accordion, and 'addRowsStack()' when we enter.
 */
const manageRowsStack = () => {
    const rowsStack: ElementTree = [[]];
    // A lodash-like path to the current row we're appending to.
    // Only difference being the empty string ('') points to the current row.
    let pathToHead = '';
    const getHead = () => customGet(rowsStack, pathToHead);
    const addRowsStack = (name: string) => {
        const newRow: any = [];
        newRow.accordionName = name;
        const currHead = customGet(rowsStack, pathToHead);
        currHead.push(newRow);
        pathToHead = pathToHead ? [pathToHead, `${currHead.length - 1}`].join('.') : `${currHead.length - 1}`;
    };

    const pop = () => {
        if (pathToHead.includes('.')) {
            pathToHead = pathToHead.slice(0, pathToHead.lastIndexOf('.'));
        } else {
            pathToHead = '';
        }
    };
    return {
        rowsStack,
        addRowsStack,
        getHead,
        pop,
    };
};
const RGrid2: React.FunctionComponent<RGridProps> = React.memo(
    ({ fields, lastRowDropdownsFlipUp = false, flexStart, smSize, xsSize, rowMargin }: RGridProps) => {
        const children = React.useMemo(() => {
            let fieldGroupsByRow = sortFieldGroupsByRow(fields);

            const { rowsStack, addRowsStack, getHead, pop } = manageRowsStack();

            const fieldRows = Object.values(fieldGroupsByRow);

            const lastVisibleRowIx: number = fieldRows.reduce((prev, row, i) => {
                const isHiddenRow = row.every((f) => f.props['dontShowCol']);
                if (isHiddenRow) {
                    return prev;
                }
                return i;
            }, 0);
            fieldRows.forEach((group, i) => {
                const orderedFieldsOfARow = orderFieldsByCol(group);
                const cols: ReactElement<{}>[] = [];
                if (orderedFieldsOfARow.length === 1) {
                    const [field] = orderedFieldsOfARow;
                    const definition = field.props['data-originaldefinition'];
                    if (definition) {
                        try {
                            const fieldDef: ViewField | FlowableField = JSON.parse(definition);
                            if (isExpressionViewField(fieldDef as ViewField) || field.props.isExpression) {
                                const config =
                                    (fieldDef as ViewField).config ?? // viewDef fields
                                    (fieldDef as FlowableField).value; // task-form fields
                                if (config?.trim()?.toLowerCase()?.startsWith('<!--accordionopen')) {
                                    addRowsStack(config);
                                    return;
                                }
                                if (config?.trim()?.toLowerCase()?.startsWith('<!--accordionclose')) {
                                    pop();
                                    return;
                                }
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
                if (orderedFieldsOfARow.length > 0) {
                    const isLastRow = i === lastVisibleRowIx;
                    orderedFieldsOfARow.forEach((field, key) => {
                        cols.push(
                            React.cloneElement(
                                encloseInACol(field, lastRowDropdownsFlipUp && isLastRow, smSize, xsSize, rowMargin),
                                { key },
                            ),
                        );
                    });
                } else {
                    cols.push(encloseInACol(undefined));
                }
                getHead().push(encloseInARow(cols, flexStart));
            });
            const flattenRowsToReactElements = (stack: ElementTree) => {
                return stack.flatMap((r: any) => {
                    if (Array.isArray(r) && (r as any).accordionName) {
                        return [
                            encloseInARow(
                                <AccordionComponent header={<X label={(r as any).accordionName} />}>
                                    {encloseInARow(flattenRowsToReactElements(r), flexStart)}
                                </AccordionComponent>,
                                flexStart,
                            ),
                        ];
                    }
                    if (Array.isArray(r)) {
                        return r;
                    }
                    return [r];
                });
            };
            const rows = flattenRowsToReactElements(rowsStack);
            return encloseInAGrid(rows);
        }, [fields, lastRowDropdownsFlipUp, flexStart, smSize, xsSize, rowMargin]);

        return <div style={{ width: '100%' }}>{children}</div>;
    },
);

const X = (props: { label: string }) => {
    const templated = useEvaluateTemplateInFormContext(props.label);
    return <SafeHtmlAsReact html={templated} />;
};

// When we print, we don't do the accordion wrapping. Instead, the accordion header merely appears normally
// as an expression. This is somewhat equivalent to having the accordion 'open'.
const RGridForPrint: React.FunctionComponent<RGridProps> = React.memo(({ fields, printRowMargin }: RGridProps) => {
    const children = React.useMemo(() => {
        const _encloseInAGrid = (rows) => <div>{rows.map((row, key) => React.cloneElement(row, { key }))}</div>;
        const _encloseInARow = (cols) => {
            return (
                <div
                    style={{
                        width: '100%',
                        boxSizing: 'border-box',
                        clear: 'both',
                    }}
                >
                    {cols}
                </div>
            );
        };
        const _encloseInACol = (field, dropdownsFlipUp = false) => {
            if (field === undefined) {
                return <div />;
            }
            if (field.props.dontShowCol) {
                return <div style={{ display: 'none' }} />;
            }
            const columnWidth = (span) => `${8.3333 * span}%`;
            const width = parseInt(field.props.span);
            const widthStr = columnWidth(width || 4); // we don't like 0

            return (
                <div
                    style={{
                        float: 'left',
                        boxSizing: 'border-box' /* To include padding and borders in the width */,
                        paddingTop: printRowMargin ?? '4px',
                        width: widthStr,
                    }}
                >
                    {displayField(field)}
                </div>
            );
        };

        let fieldGroupsByRow = sortFieldGroupsByRow(fields);

        const rows: ReactElement<{}>[] = [];
        const fieldGroups = Object.values(fieldGroupsByRow);
        fieldGroups.forEach((group, i) => {
            const orderedFieldsOfARow = orderFieldsByCol(group);
            const cols: ReactElement<{}>[] = [];
            if (orderedFieldsOfARow.length > 0) {
                orderedFieldsOfARow.forEach((field, key) => {
                    cols.push(React.cloneElement(_encloseInACol(field), { key }));
                });
            } else {
                cols.push(_encloseInACol(undefined));
            }
            const someNonemptyColumn = group.some((col) => col?.props && !col?.props?.dontShowCol);
            if (someNonemptyColumn) {
                cols.push(<div style={{ clear: 'both' }} />);
                rows.push(_encloseInARow(cols));
            }
        });
        return _encloseInAGrid(rows);
    }, [fields, printRowMargin]);

    return <div style={{ width: '100%' }}>{children}</div>;
});

const RGridController = (props: RGridProps) => {
    const printMode = useAppSelector((state: RootState) => state.printMode);
    return printMode ? <RGridForPrint {...props} /> : <RGrid2 {...props} />;
};
export default RGridController;
