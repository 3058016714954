import * as React from 'react';
import { useDispatch } from 'react-redux';
import { InjectedFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import TaskHeader from 'bpm/components/TaskDetail/TaskForm/TaskHeader';
import { ProcessTaskAttributes as TaskAttributes } from 'bpm/components/TaskDetail/TaskForm/TaskAttributes';
import { initialAssigneeValuesSelector as taskAssignmentInitialValuesSelector } from 'bpm/components/TaskDetail/selectors';
import { getIsAdmin } from 'bpm/components/TaskDetail/selectors';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { TaskForm } from 'reducers/taskFormType';

import EnhancedRGridWithVis from 'components/generics/form/EnhancedRGridTask';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { Divider, Card, Button } from '@material-ui/core';
import getAppCaseByProcessAction from 'bpm/actions/getAppCaseByProcess';
import { Helmet } from 'react-helmet';
import { useCustomError } from 'bpm/components/TaskDetail/TaskForm/customErrorContext';
import DisplayError from 'bpm/components/TaskDetail/TaskForm/customErrorContext/DisplayError';
import { ButtonProps, ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import FormSaveNotifierTrigger from 'formSaveNotifier/components/Trigger';
import { getTaskForm as getTaskFormAction } from 'bpm/task-form/actions';
import { processPageRefreshContext } from 'bpm/components/ProcessDetail/processPageRefreshContext';
import EditableTaskFormLayout from 'layout-editor/components/EditableTaskFormLayout';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import useViewConfig from 'util/hooks/useViewConfig';
import useFormHasErrors from 'bpm/components/TaskDetail/TaskForm/hooks/useFormHasErrors';
import useTaskOption from 'bpm/components/TaskDetail/TaskForm/hooks/useTaskOption';
import SaveButton from './SaveButton';
import useEvaluateOutcomeExpression from '../../../hooks/useEvaluateOutcomeExpression';
import Outcomes2, { getOutcomeUiConfigs } from '../../../Outcomes2';
import AutoSave from '../../AutoSave';
import { parse } from 'query-string';
import { useEvalExpressionInTaskContext } from 'expressions/components/EvalExpressionInTaskFormContext';
import { fromNullable, tryCatch } from 'fp-ts/lib/Option';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import WithKey from 'components/WithKey';
import SaveOrDiscard from 'offline_app/offline_entity_submits/SaveOrDiscardPrompt/SaveOrDiscard';
import isOffline from 'util/isOffline';
import OfflineWorkAlert, {
    useHasOfflineWorkToApply,
} from 'offline_app/offline_stateful_tasks/back_online/components/OfflineWorkBanner';
import isTheInstalledApp from 'util/isTheInstalledApp';
import TaskClaimedBySomeoneElsePopup from './taskClaimedBySomeoneElsePopup/TaskClaimedBySomeoneElsePopup';
import { EntityFormContextRef } from '../../types';
import { useTheme } from '@mui/material';
import Save from '@material-ui/icons/Save';
import { createCompleteButtonContext } from './createCompleteButtonContext';
const primaryButtonProps: MuiButtonProps = { color: 'primary' };

const RendersDiv: React.SFC<{ style?: React.CSSProperties }> = (props) => (
    <div style={props.style}>{props.children}</div>
);

export const isDisabled = (taskEndDate, currentUser, taskAssignee) =>
    !taskAssignee ||
    (taskAssignee !== currentUser && taskAssignee.toLowerCase() !== 'anonymoususer') ||
    Boolean(taskEndDate);

export const useSaveAndCancelDisplayExpressions = (
    formDefinition: TaskForm,
): {
    saveDisplay?: [string];
    cancelDisplay?: [string];
} => {
    return React.useMemo(
        () =>
            formDefinition.outcomes.reduce((prev, curr) => {
                if (curr.name === '_save' && curr.configs?.display) {
                    return {
                        ...prev,
                        saveDisplay: [curr.configs?.display],
                    };
                }
                if (curr.name === '_cancel' && curr.configs?.display) {
                    return {
                        ...prev,
                        cancelDisplay: [curr.configs?.display],
                    };
                }
                return prev;
            }, {}),
        [formDefinition],
    );
};

export interface FormLayoutProps {
    children?: React.ReactNode;
    onSubmit: () => void; // throws
    fields: React.ReactElement[];
    processId: string;
    taskId: string;
    formDefinition: TaskForm;
    renderLinkedEntity?: (options?: {
        viewName?: string;
        actions?: JSX.Element | null;
        toolbar?: JSX.Element | null;
        taskDisabled?: boolean;
    }) => JSX.Element | null;
    entityFormContextRef?: EntityFormContextRef;
    relatedEntityResource?: string;
    relatedEntityId?: string;
}
const TaskFormLayout: React.SFC<FormLayoutProps & InjectedFormProps> = (props) => {
    const { processId, taskId, formDefinition, renderLinkedEntity } = props;
    // get initial location on mount, so on completion we can check current location and ensure user hasn't navigated away
    // before redirecting.
    const initialPageLocation = React.useMemo(() => window.location.href, []);
    const viewConfig = useViewConfig();
    const processInstance = useAppSelector(
        (state: RootState) => processId && state.bpm.processInstances.byId[processId],
    );
    const dispatch = useDispatch();
    const expressionTesterOpen = useAppSelector((state: RootState) => state.expressionTesterOpen);

    const fc = React.useContext(formContext);
    const { refresh } = React.useContext(processPageRefreshContext);
    const taskO = useTaskOption(taskId);
    const taskLoaded = taskO.isSome();
    const taskOutcome = taskO.map((t) => t.outcome).toUndefined();
    const taskName = taskO.map((t) => t.name).toUndefined();
    const currentUser = viewConfig && viewConfig.user.login;
    const currentProcessSearch = useAppSelector((state: RootState) => state.bpm.currentProcessSearch.query);
    const isSubmitting = useAppSelector((state: RootState) => state.bpm.tasks.submitting[taskId]);
    const [errorState, errorDispatch] = useCustomError();
    const isAdmin = useAppSelector((state: RootState) => getIsAdmin(state, taskId));
    const taskEndDate = taskO.map((t) => t.endDate).toUndefined();
    const entityFormHasErrors = useFormHasErrors('record-form');
    const taskAssignee = useAppSelector(
        (state: RootState) => taskAssignmentInitialValuesSelector(state, props).assignee,
    );
    const cancelIsVisible = useEvaluateOutcomeExpression(formDefinition, '_cancel', 'visibility');
    const hasOfflineWorkToApply = useHasOfflineWorkToApply();
    const isCurrentlyWritingToOffline = useAppSelector(
        (state: RootState) => state.taskCurrentlyWritingToOffline === taskId,
    );

    const formIsDisabled = hasOfflineWorkToApply || isDisabled(taskEndDate, currentUser, taskAssignee);
    const renderLinkedEntityArea = () => {
        if (!renderLinkedEntity) {
            return null;
        }
        const viewName = viewConfig.views[formDefinition.viewName] ? formDefinition.viewName : undefined;
        if (formIsDisabled) {
            /* If the form is disabled, just use the regular Entity's saves */
            return renderLinkedEntity({
                viewName,
                taskDisabled: formIsDisabled,
            });
        }
        /* Form is editable- save should submit the form as well. */
        return (
            <WithKey>
                {({ key, incKey }) => {
                    const renderSaveButton = (ButtonProps: ButtonProps = {}) => (
                        <SaveButton
                            relatedEntityResource={props.relatedEntityResource}
                            relatedEntityId={props.relatedEntityId}
                            entityFormContextRef={props.entityFormContextRef}
                            formDefinition={formDefinition}
                            taskId={taskId}
                            fields={props.fields}
                            handleSubmit={props.handleSubmit}
                            buttonText={'Save'}
                            ButtonProps={ButtonProps}
                            onSuccess={(_, submittedTaskFormValues) => {
                                props.initialize(submittedTaskFormValues);
                                // adding incKey() which should be redundant, but is necessary because the form stays dirty
                                // despite the above.
                                // probably due to a redux-form bug.
                                incKey();
                            }}
                            submissionType="save"
                        />
                    );
                    return (
                        <div key={key}>
                            {renderLinkedEntity({
                                viewName,
                                actions: isOffline() ? (
                                    <div />
                                ) : (
                                    <RendersDiv style={{ margin: '0.5em', paddingLeft: 8 }}>
                                        {renderSaveButton()}
                                    </RendersDiv>
                                ),
                                toolbar: (
                                    <RendersDiv
                                        style={{
                                            margin: '1em',
                                            paddingLeft: 8,
                                        }}
                                    >
                                        {renderSaveButton({
                                            color: 'primary',
                                            endIcon: <Save />,
                                        })}
                                    </RendersDiv>
                                ),
                            })}
                        </div>
                    );
                }}
            </WithKey>
        );
    };
    const onCompleteSuccess = React.useCallback(
        (response) => {
            const userStillOnPage = window.location.href === initialPageLocation;
            if (response.error) {
                errorDispatch({ type: 'SET_ERROR', errorText: response.error });
                dispatch(getTaskFormAction(taskId));
            } else if (response.redirect) {
                if (response.redirect.startsWith('http')) {
                    window.location.href = response.redirect;
                } else {
                    dispatch(push(response.redirect));
                }
            } else if (response.processComplete && userStillOnPage) {
                const businessKeyFromLastProcessSearch = fromNullable(currentProcessSearch)
                    .mapNullable((url) => parse(url) as { filter?: string })
                    .mapNullable((parsedUrl) => parsedUrl.filter)
                    .chain((filterString) => tryCatch(() => JSON.parse(filterString)))
                    .mapNullable((filter) => filter['processInstance.businessKey'])
                    .toUndefined();
                const lastProcessSearchWasOnSameProcessType = Boolean(
                    businessKeyFromLastProcessSearch === processInstance?.businessKey,
                );
                if (lastProcessSearchWasOnSameProcessType) {
                    dispatch(push(`/processes${currentProcessSearch || ''}`));
                } else if (processInstance?.businessKey) {
                    dispatch(
                        push(
                            `/processes?filter=%7B"processInstance.businessKey"%3A"${processInstance.businessKey}"%7D`,
                        ),
                    );
                }
            } else if (response.nextTaskId && userStillOnPage) {
                if (processInstance && processInstance.businessKey) {
                    dispatch(getAppCaseByProcessAction(processId, processInstance.businessKey));
                }
                dispatch(push(`/processes/${processId}/tasks/${response.nextTaskId}/start`));
            } else if (userStillOnPage) {
                dispatch(push(`/processes/${processId}`));
            }
        },
        [dispatch, errorDispatch, currentProcessSearch, taskId, processInstance, processId, initialPageLocation],
    );
    const createCompleteButton = React.useCallback(
        (
            label: string,
            outcome?: string,
            forceDisabled?: boolean,
            ButtonProps?: Partial<ButtonProps>,
            muiButton = true,
        ) => (
            <SaveButton
                muiButton={muiButton}
                relatedEntityResource={props.relatedEntityResource}
                relatedEntityId={props.relatedEntityId}
                entityFormContextRef={props.entityFormContextRef}
                formDefinition={formDefinition}
                taskId={taskId}
                outcome={outcome}
                forceDisabled={forceDisabled}
                ButtonProps={ButtonProps}
                fields={props.fields}
                handleSubmit={props.handleSubmit}
                buttonText={label}
                onSuccess={onCompleteSuccess}
                submissionType="submit"
            />
        ),
        [
            onCompleteSuccess,
            props.fields,
            taskId,
            props.handleSubmit,
            formDefinition,
            props.entityFormContextRef,
            props.relatedEntityResource,
            props.relatedEntityId,
        ],
    );
    const formOnSubmit = React.useCallback((e) => {
        e.preventDefault();
        return false;
    }, []);
    const saveAndCancelDisplayExpressions: {
        saveDisplay?: [string];
        cancelDisplay?: [string];
    } = useSaveAndCancelDisplayExpressions(formDefinition);

    const outcomeUiConfigs = React.useMemo(() => getOutcomeUiConfigs(formDefinition), [formDefinition]);
    const title = useEvaluatedFormattedMessage(taskName || formDefinition?.name || formDefinition?.key || 'Casetivity');
    const printMode = useAppSelector((state: RootState) => state.printMode);
    const CardElem = printMode ? ('div' as const) : Card;
    const theme = useTheme();
    const res = useEvalExpressionInTaskContext({
        formDefinition,
        expressions: saveAndCancelDisplayExpressions,
    });
    const renderSaveButton = (
        text = res.saveDisplay?.[0] || 'Save',
        ButtonProps: Partial<ButtonProps> = outcomeUiConfigs['_save']?.ButtonProps,
        muiButton = outcomeUiConfigs['_save']?.muiButton ?? true,
    ) => {
        return (
            <SaveButton
                muiButton={muiButton}
                ButtonProps={ButtonProps}
                relatedEntityResource={props.relatedEntityResource}
                relatedEntityId={props.relatedEntityId}
                entityFormContextRef={props.entityFormContextRef}
                formDefinition={formDefinition}
                taskId={taskId}
                fields={props.fields}
                handleSubmit={props.handleSubmit}
                buttonText={text}
                onSuccess={refresh}
                submissionType="save"
            />
        );
    };
    const renderCancelButton = (
        text = res.cancelDisplay?.[0] || 'Cancel',
        ButtonProps: Partial<ButtonProps> = outcomeUiConfigs['_cancel']?.ButtonProps,
        muiButton = outcomeUiConfigs['_cancel']?.muiButton ?? true,
    ) => {
        const to = `/processes/${processId}`;
        if (muiButton === false) {
            return (
                <Link to={to} {...ButtonProps}>
                    {text}
                </Link>
            );
        }

        return (
            <Button variant="contained" component={Link} to={to} {...ButtonProps}>
                {text}
            </Button>
        );
    };
    return (
        <div>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            {expressionTesterOpen && (
                <stagedFormDefinitionContext.Consumer>
                    {({ formDefinition, setFormDefinition }) => (
                        <EditableTaskFormLayout
                            formDefinition={formDefinition}
                            onFormDefinitionChange={({ formDefinition }) => {
                                setFormDefinition(formDefinition);
                            }}
                        />
                    )}
                </stagedFormDefinitionContext.Consumer>
            )}
            {isOffline() || !isTheInstalledApp() ? null : <OfflineWorkAlert />}
            <CardElem
                style={{
                    position: 'relative',
                    overflow: 'visible',
                    marginBottom: '1em',
                    padding: printMode ? 0 : theme.spacing(2),
                }}
            >
                <TaskHeader
                    entityFormContextRef={props.entityFormContextRef}
                    isAdmin={isAdmin}
                    processId={processId}
                    title={formDefinition && taskLoaded ? taskName || formDefinition.name || formDefinition.key : ''}
                    formDefinition={formDefinition}
                    taskId={taskId}
                    disabled={formIsDisabled}
                    task={{
                        taskLoaded,
                        taskId,
                        taskName,
                        endDate: taskEndDate,
                        taskOutcome,
                        key: taskO.map((task) => task.taskKey).toUndefined(),
                    }}
                />
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                        marginTop: '0.5em',
                        paddingLeft: 'calc(0.5em + 4px)',
                    }}
                >
                    <TaskAttributes taskId={taskId} isAdmin={isAdmin} processId={processId} />
                </div>
                <Divider style={{ margin: 12 }} />
                <div>
                    <form onSubmit={formOnSubmit} autoComplete="off">
                        <createCompleteButtonContext.Provider
                            value={{
                                createCompleteButton,
                                renderCancelButton,
                                renderSaveButton,
                            }}
                        >
                            {props.fields && (
                                <EnhancedRGridWithVis fields={props.fields} formDefinition={formDefinition} />
                            )}
                            <div
                                style={{
                                    paddingBottom: 10,
                                    paddingTop: 10,
                                    paddingLeft: 10,
                                    overflowWrap: 'break-word',
                                }}
                            >
                                <DisplayError errorText={errorState.errorText} errorKey={errorState.key} />
                                {taskLoaded && !taskEndDate && !isOffline() && !printMode ? (
                                    <div style={{ display: 'inline-block', width: '100%' }}>
                                        <SaveOrDiscard taskId={taskId} processId={processId} />
                                        {renderSaveButton()}
                                        &nbsp;&nbsp;
                                        {cancelIsVisible && renderCancelButton()}
                                        &nbsp;&nbsp;
                                        {formDefinition &&
                                        formDefinition.outcomes &&
                                        formDefinition.outcomes.filter(
                                            (o) =>
                                                o.name &&
                                                o.name.toLowerCase() !== '_save' &&
                                                o.name.toLowerCase() !== '_cancel',
                                        ).length > 0 ? (
                                            <Outcomes2
                                                formDefinition={formDefinition}
                                                taskLoaded={taskLoaded}
                                                taskOutcome={taskOutcome}
                                                entityFormHasErrors={entityFormHasErrors}
                                                createButton={createCompleteButton}
                                            />
                                        ) : (
                                            createCompleteButton('Complete', undefined, undefined, primaryButtonProps)
                                        )}
                                    </div>
                                ) : null}
                            </div>
                        </createCompleteButtonContext.Provider>
                    </form>
                </div>
                <AutoSave
                    on={formDefinition.autoSave && !formIsDisabled && fc.isDirty && !isSubmitting}
                    taskId={taskId}
                    formDefinition={formDefinition}
                />
                <FormSaveNotifierTrigger
                    when={!isCurrentlyWritingToOffline && !formIsDisabled && fc.isDirty && !isSubmitting}
                />
            </CardElem>
            {renderLinkedEntityArea()}
            <TaskClaimedBySomeoneElsePopup />
        </div>
    );
};

export default TaskFormLayout;
